import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import './Components-Styles/Navbarstyles.scss';
import { FaWifi } from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Logo from '../Images/Logo.png';


function ColorSchemesExample() {
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <>


      <Navbar bg="black" expand="sm" variant="dark">
        <Container>
          <Navbar.Brand className='logo' href="/">
            <img src={Logo} alt='logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="fontnavbar">
              <Nav.Link className='navlink' href="/" activeStyle>Ballina</Nav.Link>
              <Nav.Link className='navlink' href="/rrethnesh" activeStyle> Rreth Nesh</Nav.Link>
              <Nav.Link className='navlink' href="/sherbimet" >Sherbimet</Nav.Link>
              {/* <Nav.Link className='navlink' href="/lajme">Lajme</Nav.Link> */}
              <Nav.Link className='navlink' href="/kontakti">Kontakti</Nav.Link>
              <div className='wifi'>
                <button onClick={() => openInNewTab('https://www.speedtest.net/run/')}>
                  <FaWifi />
                </button>
              </div>
              <div className='buttonnn'>

                <div className='button2'>
                  <Button href='/login' variant="outline-primary">Kyquni</Button>{' '}
                </div>
                <div className='buttonni'>
                  <Button href='/register' variant="outline-success" style={{ marginLeft: '0.3cm' }}>Regjistrohuni</Button>{' '}
                </div>
              </div>


            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  );
}

export default ColorSchemesExample;