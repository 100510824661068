import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes ,  Route } from 'react-router-dom';
import Ballina from './Pages/ballina';
import Rrethnesh from './Pages/rrethnesh';
import Biznes from './Pages/biznes';
import Lajme from './Pages/lajme';
import NoPage from './Pages/nopage';
import Kyquni from './Forms/login';
import Sherbimet from './Pages/sherbimet';
import Layout from './Pages/layout';
import Kontakt from './Pages/kontakti';
import Login from './Forms/login';
import Register from './Forms/register';
import Readmore1 from './MorePage/readmore1';
import Readmore2 from './MorePage/readmore2';
import Readmore3 from './MorePage/readmore3';
import Readmore4 from './MorePage/readmore4';
import Readmore5 from './MorePage/readmore5';
import Readmore6 from './MorePage/readmore6';
import Kushtet from './Pages/kushteteabonimit';


function App() {
  return (
    <>
    <BrowserRouter>
     <Routes>
      <Route path='/' element={<Layout/>}>
        <Route index element={<Ballina/>}/>
        <Route path='/rrethnesh' element={<Rrethnesh/>}/>
        <Route path='/Sherbimet' element={<Sherbimet/>}/>
        {/* <Route path='/lajme' element={<Lajme/>}/> */}
        <Route path='/biznes' element={<Biznes/>}/>
        <Route path='/kyquni' element={<Kyquni/>}/>
        <Route path='/kontakti' element={<Kontakt/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/kushteteabonimit' element={<Kushtet/>}/>
        <Route path='/readmore1.js' element={<Readmore1/>}/>
        <Route path='/readmore2.js' element={<Readmore2/>}/>
        <Route path='/readmore3.js' element={<Readmore3/>}/>
        <Route path='/readmore4.js' element={<Readmore4/>}/>
        <Route path='/readmore5.js' element={<Readmore5/>}/>
        <Route path='/readmore6.js' element={<Readmore6/>}/>
        <Route path='*' element={<NoPage/>}/>

      </Route>
     </Routes>
    </BrowserRouter>

    </>
  );
}

export default App; 
